import { useState } from "react";
import "./app.min.css";

function App() {
  const [questionNum01, setQuestionNum01] = useState("0");
  const [questionNum02, setQuestionNum02] = useState("0");
  const [realAnswer, setRealAnswer] = useState("?");
  const [calcAnswer, setCalcAnswer] = useState("?");
  const [correctAnswer, setCorrectAnswer] = useState("??");
  const onClickChangeQuestion = () => {
    setCorrectAnswer("???");
    setCalcAnswer("");
    setRealAnswer("・・");
    const newNum01 = Math.floor(Math.random() * 10);
    setQuestionNum01(newNum01);
    const newNum02 = Math.floor(Math.random() * 10);
    setQuestionNum02(newNum02);
  };
  const onChangeNumber = (e) => {
    const newAnswer = Number(e.target.value);
    setCalcAnswer(newAnswer);
  };
  const onClickAnswer = () => {
    const newAnswer = Number(questionNum01) + Number(questionNum02);
    setRealAnswer(newAnswer);
    if (calcAnswer === newAnswer) {
      setCorrectAnswer("せいかい！！");
    } else {
      setCorrectAnswer("ちがうよ！");
    }
  };
  return (
    <>
      <div className="ly_body">
        <h1>けいさんドリル</h1>
        <div className="main">
          <div className="question-area">
            <button className="btn" onClick={onClickChangeQuestion}>
              つぎのもんだい
            </button>
            <p>
              <span>{questionNum01}</span>+<span>{questionNum02}</span>=
              <span>?</span>
            </p>
          </div>

          <div className="calc-area">
            <input
              type="number"
              placeholder="こたえをにゅうりょく"
              value={calcAnswer}
              onChange={onChangeNumber}
            />
            <button className="btn answer-btn" onClick={onClickAnswer}>
              こたえあわせ
            </button>
            <br />
          </div>

          <div className="answer-area">
            せいかいは：<span className="real-answer">{realAnswer}</span>
            <br />
            <p className="correct-answer">{correctAnswer}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
